import React from "react";
import "./UnderConstruction.css";

const UnderConstruction = ({ language }) => {
  return (
    <div className="under-construction">
      {language === "en" ? (
        <>
          <h1>Little emotional lions</h1>
          <h2>Under Construction</h2>
          <p>
            We'll be online soon! Get excited for a sneak peek into how little
            emotional lions in Hamburg are empowered through my coaching. Check
            back soon – it’s worth it!
          </p>
        </>
      ) : (
        <>
          <h1>Kleine Gefühlslöwen</h1>
          <h2>Im Aufbau</h2>
          <p>
            Wir sind bald online! Freut euch auf spannende Einblicke, wie kleine
            Gefühlslöwen in Hamburg mit meinem Coaching stark gemacht werden.
            Schaut bald wieder vorbei – es lohnt sich!
          </p>
        </>
      )}
    </div>
  );
};

export default UnderConstruction;
