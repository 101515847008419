import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import UnderConstruction from "./UnderConstruction";
import "./App.css";

function App() {
  const [language, setLanguage] = useState("en");

  useEffect(() => {
    const browserLanguage = navigator.language || navigator.userLanguage;
    if (browserLanguage.startsWith("de")) {
      setLanguage("de");
    } else {
      setLanguage("en");
    }
  }, []);

  const toggleLanguage = () => {
    setLanguage((prevLang) => (prevLang === "en" ? "de" : "en"));
  };

  return (
    <div className="App">
      <Helmet>
        <title>
          {language === "en"
            ? "Kleine Gefühlslöwen - Under Construction"
            : "Kleine Gefühlslöwen - Im Aufbau"}
        </title>
        <meta
          name="description"
          content={
            language === "en"
              ? "Individual coaching in Hamburg: Parents and children learn to understand and express feelings. Strengthen your family with our support."
              : "Individuelles Coaching in Hamburg: Eltern und Kinder lernen, Gefühle zu verstehen und auszudrücken. Stärken Sie Ihre Familie mit unserer Unterstützung."
          }
        />
        <script type="application/ld+json">
          {`
            {
              "@context": "http://schema.org",
              "@type": "Website",
              "url": "https://www.kleinegefühlslöwen.de/",
              "name": "${
                language === "en"
                  ? "Kleine Gefühlslöwen"
                  : "Kleine Gefühlslöwen"
              }",
              "description": "${
                language === "en"
                  ? "Individual coaching in Hamburg: Parents and children learn to understand and express feelings. Strengthen your family with our support."
                  : "Individuelles Coaching in Hamburg: Eltern und Kinder lernen, Gefühle zu verstehen und auszudrücken. Stärken Sie Ihre Familie mit unserer Unterstützung."
              }"
            }
          `}
        </script>
      </Helmet>
      <button className="toggle-button" onClick={toggleLanguage}>
        {language === "en" ? "Deutsch" : "English"}
      </button>
      <UnderConstruction language={language} />
    </div>
  );
}

export default App;
